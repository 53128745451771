header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ======== CTA  ======== */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ======== HEADER SOCIALS  ======== */

.header__socialss {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  margin-bottom: 14rem;
}

.header__socialss::after {
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ======== ME  ======== */
.me {
  margin-top: 20rem;

}

@media screen and (min-height: 1020px) { 
  .me {
    margin-top: 10rem;
  }

}
@media screen and (max-height: 1200px) { 
  .me {
    margin-top: 10rem;
  }

}



/* ======== SCROLL DOWN  ======== */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
  margin-bottom: 14rem;
}

/*====== MEDIA QUERIES (MEDIUM DEVİCES */
@media screen and (max-width: 1024px) {
 header {
   height: 68vh;
 }

}

/*====== MEDIA QUERIES (SMALL DEVİCES) */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socialss, .scroll__down {
    display: none;
  }
  .me {
    margin-top: 8rem;
  
  }
}

