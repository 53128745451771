.container.testimonials__container {
  width: 40%;
  padding-bottom: 4rem;
}


.client__avatar {
  width: 4rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  margin: 0 auto 1rem;
  border: 0.4rem solid var(--color-primary-variant);
  align-items: center;
  border-radius: 50% ;

} 

.testimonials {
  background: var(--color-bg-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
}

.client__review {
  color: var(--color-light);
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--color-primary);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .container.testimonials__container {
    width: 60%;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .container.testimonials__container {
    width: var(--container-width-sm);
  }

  .client__review {
    width: var(--container-width-sm);
  }
}
