.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  color: #fff;
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
  color: #fff;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
  color: #fff;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.new-button-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  margin-bottom:-4rem;
  margin-top:-1.5rem;
}

.new-buttons{ 
  color: '#fff';
  cursor:pointer;
}

.button-work-styles {
  margin-top: 1rem;
}
.ant-tabs-nav-wrap{
  color: #fff ;
}

.ant-tabs-nav {
  margin: -1rem 0 -2rem 0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500;
  padding-left:1rem
  
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab { /* For tabs border */
  color: var(--color-light);

  
}

.ant-tabs-top > .ant-tabs-nav::before { /* For the line to the right and close to the tabs */
  border-color: black;
  
}

.ant-tabs > .ant-tabs-nav { /* So that there is no gap between the content and tabs */
  margin-bottom: 0;
  
}

.ant-tabs-content-holder {
  padding: 15px;
  border-top: transparent; /* Remove the top border so that there is no overlap*/
}


/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}


/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}