*{
  margin: 0;
  padding: 0;
}

body{
  background: black;
}

.main{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.box{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #4db5ff;
  position: relative;
}

.box span{
  position: absolute;
  top: -100%;
  left: -105px;
  width: 800%;
  height: 80px;
  border: 4px solid #4db5ff;
  border-radius: 50%;
  animation: anim 3s ;
}

.box span:nth-child(1){
  transform: rotate(-60deg);
  animation: anim2 3s ;
}

.box span:nth-child(2){
  transform: rotate(60deg);
  animation: anim3 4s ;
}

@keyframes anim{
  0%,100%{transform: rotate(0deg);}
  50%{transform: rotate(360deg);}
}

@keyframes anim2{
  0%,100%{transform: rotate(-60deg);}
  50%{transform: rotate(-300deg);}
}

@keyframes anim3{
  0%,100%{transform: rotate(60deg);}
  50%{transform: rotate(300deg);}
}